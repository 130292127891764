import { useContext, useState, lazy, Suspense, ReactNode } from 'react';
import { BrowserRouter, Route, Routes, useLocation, Navigate } from 'react-router-dom';
import { isInIframe } from 'utils/iframe';
import { PostMessageContext } from 'component/postMessage/PostMessageContext';
import { Header } from 'component/partial/Header';
import { SiteUserHeader } from 'component/partial/SiteUserHeader';
import { Box } from '@mui/material';
import qs from 'query-string';
import Cookie from 'js-cookie';
import { Token } from 'api/types';
import { AcceptInvite } from 'component/partial/AcceptInvite';
import { decodeToken, getUserSession, isOldStyleToken, isSiteAccess } from '../../utils/token';
import { linkHelper } from '../../linkHelper';
import { SHOW_NEW_DESIGN, SHOW_TICKETS } from '../../constants';
import { getIsWpSiteControl } from 'utils';
import { TaskNotificationProvider } from './TaskNotificationProvider';

const PHPMyAdmin = lazy(
  () => import(/* webpackPrefetch: true */ 'component/partial/Redirects/PHPMyAdmin')
);
const WordpressManager = lazy(
  () => import(/* webpackPrefetch: true */ 'component/partial/Redirects/WordpressManager')
);
const Dashboard = lazy(() => import(/* webpackPrefetch: true */ 'component/view/Dashboard'));
const SiteDetails = lazy(() => import(/* webpackPrefetch: true */ 'component/view/SiteDetails'));
const CreateSite = lazy(() => import(/* webpackPrefetch: true */ 'component/view/CreateSite'));
const Sites = lazy(() => import(/* webpackPrefetch: true */ 'component/view/Sites'));
const Login = lazy(() => import(/* webpackPrefetch: true */ 'component/view/Auth/Login'));
const Logout = lazy(() => import(/* webpackPrefetch: true */ 'component/view/Auth/Logout'));
const LoginBase = lazy(() => import(/* webpackPrefetch: true */ 'component/view/Auth/LoginBase'));
const SetPassword = lazy(
  () => import(/* webpackPrefetch: true */ 'component/view/Auth/SetPassword')
);
const ResetPassword = lazy(
  () => import(/* webpackPrefetch: true */ 'component/view/Auth/ResetPassword')
);
const Tickets = lazy(() => import(/* webpackPrefetch: true */ 'component/view/Tickets'));
const AccountUsers = lazy(() => import(/* webpackPrefetch: true */ 'component/view/AccountUsers'));
const AccountUserAccept = lazy(
  () => import(/* webpackPrefetch: true */ 'component/view/AccountUsers/Accept')
);
const Profile = lazy(() => import(/* webpackPrefetch: true */ 'component/view/Profile'));
const ChangePassword = lazy(
  () => import(/* webpackPrefetch: true */ 'component/view/Profile/ChangePassword')
);
const TwoFactorAuthentication = lazy(
  () => import(/* webpackPrefetch: true */ 'component/view/Profile/TwoFactorAuthentication')
);
const SiteTemplates = lazy(
  () => import(/* webpackPrefetch: true */ 'component/partial/SiteTemplates')
);
const ViewSiteTemplate = lazy(
  () => import(/* webpackPrefetch: true */ 'component/partial/SiteTemplates/ViewSiteTemplate')
);
const PostLogin = lazy(() => import(/* webpackPrefetch: true */ 'component/view/Auth/PostLogin'));

// new design modules
const AppNew = lazy(() => import('component/new_design/root/App'));

function Authenticated({
  hideHeader,
  children,
}: {
  readonly hideHeader?: boolean;
  readonly children: ReactNode;
}) {
  const { pathname, search } = useLocation();
  const isIframe = isInIframe();
  let userSession = getUserSession();
  const qsParams = qs.parse(window.location.search);
  const { getSdk } = useContext(PostMessageContext);
  const sdk = getSdk();
  const [drawerOpen, setDrawerOpen] = useState(false);
  const token = userSession && decodeToken(userSession);
  const isWpSiteControl = getIsWpSiteControl();
  const loginURL = `${linkHelper.login.getLink()}?retUrl=${pathname}${search}`;

  if (token && isOldStyleToken(token as Token)) {
    Cookie.remove('user_session');
    window.location.href = `${window.location.origin}/login`;
  }

  if (typeof qsParams.token === 'string') {
    if (isIframe) {
      window.sessionStorage.setItem('user_session', qsParams.token);
    } else {
      Cookie.set('user_session', qsParams.token as string, {
        expires: 3560,
        sameSite: 'strict',
      });
    }
    userSession = qsParams.token;
  }

  if (!userSession && !isWpSiteControl) {
    window.location.href = loginURL;
  }

  if (typeof qsParams.token === 'string' || userSession) {
    return (
      <TaskNotificationProvider>
        <Box
          sx={theme => ({
            ...(!isWpSiteControl
              ? {
                  [theme.breakpoints.up('md')]: {
                    display: 'flex',
                  },
                }
              : {}),
            '& > div:first-of-type': {
              position: 'sticky',
              top: '0',
              zIndex: '1299',
              ...(!isWpSiteControl
                ? {
                    [theme.breakpoints.up('md')]: {
                      position: 'static',
                      display: 'flex',
                      flex: '0 0 12rem',
                    },
                    [theme.breakpoints.up('lg')]: {
                      flexBasis: '17.5rem',
                    },
                  }
                : {}),
            },
            '& > main:first-of-type': {
              padding: '1.5rem 0',
              [theme.breakpoints.up('md')]: {
                flex: '1 1 auto',
                margin: '0 auto',
                padding: '2rem 0',
                minWidth: '0',
              },
            },
          })}
        >
          {(!sdk || sdk?.header === 'show') &&
          !hideHeader &&
          token &&
          !isWpSiteControl &&
          !isSiteAccess(token as Token) ? (
            <Header
              drawerOpen={drawerOpen}
              hasData
              onDrawerOpenToggle={() => setDrawerOpen(value => !value)}
            />
          ) : null}
          {isWpSiteControl ? <SiteUserHeader /> : null}
          {children}
        </Box>
      </TaskNotificationProvider>
    );
  }

  if (pathname !== linkHelper.login.getLink()) {
    window.location.href = loginURL;
  }

  return null;
}

// eslint-disable-next-line react/no-multi-comp
export default function App() {
  const isWpSiteControl = getIsWpSiteControl();

  return (
    <BrowserRouter>
      <Routes>
        <Route
          path={linkHelper.loginWpsitecontrol.path}
          element={
            <Suspense fallback={null}>
              <Login isWpSiteControl={isWpSiteControl} />
            </Suspense>
          }
        />
        <Route
          path={linkHelper.login.path}
          element={
            <Suspense fallback={null}>
              <LoginBase isWpSiteControl={isWpSiteControl} />
            </Suspense>
          }
        />
        <Route
          path={linkHelper.postLogin.path}
          element={
            <Suspense fallback={null}>
              <PostLogin />
            </Suspense>
          }
        />
        <Route
          path={linkHelper.setPassword.path}
          element={
            <Suspense fallback={null}>
              <SetPassword isWpSiteControl={isWpSiteControl} />
            </Suspense>
          }
        />
        <Route
          path={linkHelper.logout.path}
          element={
            <Suspense fallback={null}>
              <Logout />
            </Suspense>
          }
        />
        <Route
          path={linkHelper.resetPassword.path}
          element={
            <Suspense fallback={null}>
              <ResetPassword />
            </Suspense>
          }
        />
        <Route
          path={linkHelper.profile.changePassword.path}
          element={
            <Authenticated hideHeader>
              <Suspense fallback={null}>
                <ChangePassword />
              </Suspense>
            </Authenticated>
          }
        />
        <Route
          path={linkHelper.profile.twoFactorAuthentication.path}
          element={
            <Authenticated hideHeader>
              <Suspense fallback={null}>
                <TwoFactorAuthentication />
              </Suspense>
            </Authenticated>
          }
        />

        <Route
          path={linkHelper.manageWordpress.path}
          element={
            <Authenticated hideHeader>
              <Suspense fallback={null}>
                <WordpressManager />
              </Suspense>
            </Authenticated>
          }
        />

        <Route
          path={linkHelper.managePhpadmin.path}
          element={
            <Authenticated hideHeader>
              <Suspense fallback={null}>
                <PHPMyAdmin />
              </Suspense>
            </Authenticated>
          }
        />
        {SHOW_TICKETS ? (
          <Route
            path={linkHelper.tickets.path}
            element={
              <Authenticated>
                <Suspense fallback={null}>
                  <Tickets isWpSiteControl={isWpSiteControl} />
                </Suspense>
              </Authenticated>
            }
          />
        ) : null}
        <Route path={linkHelper.users.path}>
          <Route
            index
            element={
              <Authenticated>
                <Suspense fallback={null}>
                  <AccountUsers isWpSiteControl={isWpSiteControl} />
                </Suspense>
              </Authenticated>
            }
          />
          <Route
            path={linkHelper.users.accept.path}
            element={
              <Suspense fallback={null}>
                <AccountUserAccept isWpSiteControl={isWpSiteControl} />
              </Suspense>
            }
          />
        </Route>
        <Route
          path={linkHelper.profile.path}
          index
          element={
            <Authenticated>
              <Suspense fallback={null}>
                <Profile />
              </Suspense>
            </Authenticated>
          }
        />
        {!isWpSiteControl && (
          <Route
            path={linkHelper.dashboard.path}
            element={
              <Authenticated>
                <Suspense fallback={null}>
                  <Dashboard isWpSiteControl={isWpSiteControl} />
                </Suspense>
              </Authenticated>
            }
          />
        )}
        {SHOW_NEW_DESIGN ? <Route path={linkHelper.newDesign.path} element={<AppNew />} /> : null}
        <Route path={linkHelper.sites.path}>
          <Route
            index
            element={
              <Authenticated>
                <Suspense fallback={null}>
                  <Sites isWpSiteControl={isWpSiteControl} />
                </Suspense>
              </Authenticated>
            }
          />
          <Route
            path={linkHelper.sites.inviteAccept.path}
            element={
              <Authenticated>
                <Suspense fallback={null}>
                  <AcceptInvite />
                </Suspense>
              </Authenticated>
            }
          />
          <Route
            path={linkHelper.sites.viewTemplate.path}
            element={
              <Authenticated>
                <Suspense fallback={null}>
                  <ViewSiteTemplate />
                </Suspense>
              </Authenticated>
            }
          />
          <Route
            path={linkHelper.sites.templates.path}
            element={
              <Authenticated>
                <Suspense fallback={null}>
                  <SiteTemplates />
                </Suspense>
              </Authenticated>
            }
          />
          <Route
            path={linkHelper.sites.siteDetails.path}
            element={
              <Authenticated>
                <Suspense fallback={null}>
                  <SiteDetails isWpSiteControl={isWpSiteControl} />
                </Suspense>
              </Authenticated>
            }
          />
          {!isWpSiteControl && (
            <Route
              path={linkHelper.sites.create.path}
              element={
                <Authenticated>
                  <Suspense fallback={null}>
                    <CreateSite />
                  </Suspense>
                </Authenticated>
              }
            />
          )}
        </Route>
        {!isWpSiteControl && (
          <Route path="*" element={<Navigate to={linkHelper.dashboard.getLink()} replace />} />
        )}
        {isWpSiteControl ? (
          <Route path="*" element={<Navigate to={linkHelper.sites.getLink()} replace />} />
        ) : null}
      </Routes>
    </BrowserRouter>
  );
}
