import { Task, useProfileTaskPolling } from 'api/profile';
import { useSnackbar } from 'component/hooks/useSnackbar';
import { createContext, PropsWithChildren, useEffect, useMemo, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';

export const TaskNotificationContext = createContext<{
  readonly startWatchingTasks: () => void;
}>({ startWatchingTasks: () => {} });

export const TaskNotificationProvider = ({ children }: PropsWithChildren) => {
  const { enqueueSnackbar } = useSnackbar();
  const { t } = useTranslation();
  const [watching, setWatching] = useState(false);
  const profile = useProfileTaskPolling(watching);
  const previousTasks = useRef(profile.data?.data.result?.tasks);
  const notifiedTasks = useRef<Set<string | undefined>>(new Set());
  const firstRunComplete = useRef(false);

  const getTaskMessage = (task: Task) => {
    const success = task.task_status === 'DONE';

    switch (task.task_type) {
      case 'backup':
        return success ? t('backup_created_successfully') : t('backup_creation_failed');
      case 'restore':
      case 'automated_restore':
        return success ? t('backup_restored_successfully') : t('backup_restoration_failed');
      default:
        return null;
    }
  };

  useEffect(() => {
    const newTasks = profile.data?.data.result?.tasks;

    // When the tasks are first fetched, we want to ignore any tasks that are already done
    if (!firstRunComplete.current && newTasks) {
      for (const task of newTasks || []) {
        if (task.task_status === 'DONE' || task.task_status === 'ERROR') {
          notifiedTasks.current.add(task.id);
        }
      }
      firstRunComplete.current = true;
    }

    // Check if any new tasks have finished and display a notification
    for (const task of newTasks || []) {
      const taskFinished = task.task_status === 'DONE' || task.task_status === 'ERROR';
      const taskAlreadyNotified = task.id && notifiedTasks.current.has(task.id);

      if (taskFinished && !taskAlreadyNotified) {
        notifiedTasks.current.add(task.id);

        const message = getTaskMessage(task);

        if (message) {
          enqueueSnackbar(message, {
            variant: task.task_status === 'DONE' ? 'success' : 'error',
          });
        }
      }
    }

    // Enable watching if there are any new or in-progress tasks, disable otherwise
    setWatching(
      newTasks?.some(task => task.task_status === 'NEW' || task.task_status === 'PROGRESS') || false
    );

    previousTasks.current = newTasks;
  }, [profile.data]);

  const value = useMemo(
    () => ({
      startWatchingTasks: () => {
        setWatching(true);
      },
    }),
    []
  );

  return (
    <TaskNotificationContext.Provider value={value}>{children}</TaskNotificationContext.Provider>
  );
};
